<template>
  <div class="mt-2 pt-2">
    <v-card>
      <v-toolbar flat color="transparent">
        <v-toolbar-title> HS Code Search </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" text>X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-list dense>
              <v-subheader style="font-size: 18px">
                Section <v-spacer></v-spacer>
                <v-chip
                  label
                  small
                  v-if="selectedSection"
                  close
                  @click:close="selectedSection=null, params.section=null"
                  close-icon="close"
                  >Selected: {{ selectedSection }}</v-chip
                >
              </v-subheader>
              <v-text-field
                label="Search"
                outlined
                dense
                class="px-3 my-0 py-0"
                prepend-inner-icon="search"
                clearable
                v-model="searchSection"
              >
              </v-text-field>
              <v-list
                dense
                style="height: 50vh; overflow-y: auto"
                v-loading="loadingSection"
                class="mt-0 pt-0"
              >
                <v-list-item-group v-model="params.section" color="primary">
                  <v-list-item
                    v-for="section in filterSections"
                    :key="section.code"
                    :value="section.code"
                    @click="
                      (params.chapter = null),
                        changeSelectedSection(section.code)
                    "
                  >
                    <v-list-item-action>
                      <v-chip>{{ section.code }}</v-chip>
                    </v-list-item-action>
                    <v-list-item-content style="max-width: 350px">
                      <v-list-item-title
                        class="text-wrap"
                        style="font-size: 12px"
                        v-html="section.explanation"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item
                  v-if="!loadingSection && filterSections.length == 0"
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No sections found.</span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
            </v-list>
          </v-col>
          <v-col cols="12" sm="3">
            <v-list dense>
              <v-subheader style="font-size: 18px"> Chapter <v-spacer></v-spacer>
                <v-chip
                  label
                  small
                  v-if="selectedChapter"
                  close
                  @click:close="selectedChapter=null, params.chapter=null"
                  close-icon="close"
                  >Selected: {{ selectedChapter }}</v-chip
                ></v-subheader>
              <v-text-field
                label="Search"
                outlined
                dense
                class="px-3 my-0 py-0"
                prepend-inner-icon="search"
                clearable
                v-model="searchChapter"
              >
              </v-text-field>
              <v-list
                dense
                style="height: 50vh; overflow-y: auto"
                v-loading="loadingChapter"
                class="mt-0 pt-0"
              >
                <v-list-item-group v-model="selectedChapter" color="primary">
                  <v-list-item
                    v-for="chapter in filterChapters"
                    :key="chapter.code"
                    :value="chapter.code"
                  >
                    <v-list-item-action>
                      <v-chip>{{ chapter.code }}</v-chip>
                    </v-list-item-action>
                    <v-list-item-content style="max-width: 350px">
                      <v-list-item-title
                        class="text-wrap"
                        style="font-size: 12px"
                        v-html="chapter.explanation"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item
                  v-if="
                    !loadingChapter &&
                    filterChapters.length == 0 &&
                    params.filter
                  "
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No chapters found.</span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list dense>
              <v-subheader style="font-size: 18px"> HS Codes </v-subheader>
              <v-text-field
                label="Search"
                outlined
                dense
                class="px-3 my-0 py-0"
                prepend-inner-icon="search"
                clearable
                v-model="params.search"
              >
              </v-text-field>
              <v-list
                dense
                style="height: 50vh; overflow-y: auto"
                v-loading="loading"
                class="mt-0 pt-0"
              >
                <v-list-item
                  v-for="code in hsCodes.data"
                  :key="code.id"
                  @click="selectCode(code)"
                  :disabled="!code.code"
                >
                  <v-list-item-action v-if="code.alternateCode">
                    <v-chip>{{ code.alternateCode }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="code.alternateCode"
                      class="text-wrap"
                      style="font-size: 14px"
                      v-html="
                        code.explanation ? code.explanation : code.description
                      "
                    ></v-list-item-title>
                    <v-subheader v-else style="font-size: 14px">{{
                      code.description
                    }}</v-subheader>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading && hsCodes.total == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No codes found.</span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
              <v-row justify="center" class="my-2">
                <el-pagination
                  style="color: var(--v-primaryText-base)"
                  :current-page.sync="page"
                  :pager-count="5"
                  :page-size.sync="params.limit"
                  :page-sizes="[105, 25, 50, 100]"
                  :layout="
                    !$vuetify.breakpoint.mobile
                      ? 'prev, pager, next, total'
                      : 'prev, pager, next'
                  "
                  :total="hsCodes.total"
                >
                </el-pagination>
              </v-row>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    chapters: [],
    dialog: false,
    hsCodes: {
      total: 0,
      data: [],
    },
    item: {},
    loading: false,
    loadingCode: false,
    loadingChapter: false,
    loadingSection: false,
    page: 1,
    params: {
      limit: 10,
      offset: 0,
      search: null,
      section: null,
      chapter: null,
    },
    saving: false,
    savingProductCountry: false,
    searchChapter: null,
    searchCountry: null,
    selectedCountry: null,
    searchSection: null,
    searchTimer: null,
    sections: [],
    selectedCode: null,
    selectedChapter: null,
    selectedSection: null,
  }),
  watch: {
    selectedChapter: {
      immediate: true,
      handler(val) {
        if (val) {
          (this.hsCodes = {
          total: 0,
          data: [],
        }),
          this.params.section = this.chapters.find((x) => x.code == val).parent;
          this.selectedSection = this.params.section;
          this.params.chapter = val.length < 3 ? "0" + val : val;
          if (!this.dialog) {
            this.applyFilter();
          }
        } else {
          this.params.chapter = null;
          if (!this.dialog) {
            this.applyFilter();
          }
        }
      },
    },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      this.applyFilter();
    },
    "params.search": {
      async handler() {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.loading = true;
        (this.hsCodes = {
          total: 0,
          data: [],
        }),
          (this.searchTimer = setTimeout(async () => {
            this.hsCodes = await this.$API.getHSCodes({
              params: this.params,
            });
            this.loading = false;
          }, 500));
      },
    },
  },
  computed: {
    activeChapter() {
      let result = {};
      if (this.selectedChapter) {
        result = this.chapters.find((x) => x.code == this.selectedChapter);
      }
      return result;
    },
    activeSection() {
      let result = {};
      if (this.selectedSection) {
        result = this.sections.find((x) => x.code == this.selectedSection);
      }
      return result;
    },
    filterChapters() {
      let result = this.chapters;
      if (this.selectedSection) {
        result = result.filter((x) => x.parent == this.selectedSection);
      }
      if (this.searchChapter) {
        result = result.filter(
          (section) =>
            section.code
              .toLowerCase()
              .includes(this.searchChapter.toLowerCase()) ||
            section.explanation
              .toLowerCase()
              .includes(this.searchChapter.toLowerCase())
        );
      }
      return result;
    },
    filterSections() {
      let result = this.sections;
      if (this.searchSection) {
        result = result.filter(
          (section) =>
            section.code
              .toLowerCase()
              .includes(this.searchSection.toLowerCase()) ||
            section.explanation
              .toLowerCase()
              .includes(this.searchSection.toLowerCase())
        );
      }
      return result;
    },
  },
  async created() {
    this.getHSCodeSections();
    this.applyFilter();
  },
  methods: {
    addCountryCode() {
      this.countryCodeItem = {
        hsCodeId: this.item.id,
        countryRegionId: this.selectedCountry.id,
        countryCode: this.selectedCountry.iso2,
      };
      this.countryCodeModal = true;
    },
    async applyFilter() {
      this.loading = true;
      this.hsCodes = await this.$API.getHSCodes({
        params: this.params,
      });
      this.loading = false;
    },
    changeSelectedSection(val) {
      if (this.selectedSection == val) {
        this.selectedSection = null;
      } else {
        this.selectedSection = val;
      }
    },
    async getHSCodeSections() {
      this.loadingSection = true;
      this.loadingChapter = true;
      let sections = await this.$API.getHSCodeSections();
      this.sections = sections.data;
      this.loadingSection = false;
      let chapters = await this.$API.getAllHSCodeSubSections();
      chapters.data.forEach((x) => {
        x.code = x.code.length == 3 ? "0" + x.code : x.code;
      });
      this.chapters = chapters.data;
      this.loadingChapter = false;
      this.params.offset = 0;
    },
    async getSubsections(val) {
      this.chapters = [];
      this.loadingChapter = true;
      let chapters = await this.$API.getHSCodeSubSections(val);
      chapters.data.forEach((x) => {
        x.code = x.code.length == 3 ? "0" + x.code : x.code;
      });
      this.chapters = chapters.data;
      this.loadingChapter = false;
    },
    selectCode(item) {
      this.$emit("select", item);
    },
  },
};
</script>
